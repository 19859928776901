<template>
  <uds-simple-dropdown ref="dropdown" placement="bottom-end">
    <template #inputContent>
      <div class="avatar">
        <div class="logo-title">
          <img
            v-if="societyStore.config?.logo && !isAdminPage"
            :src="societyStore.config.logo"
            style="width: 24px; height: 24px; border-radius: 9999px"
          >
          <uds-icon
            v-else
            icon-name="account_circle"
            :color="primaryColors.primary500"
            size="medium"
          />
          <div class="uds-paragraph truncate">
            <span v-if="societyStore.society && !isAdminPage">
              <CommonsTextTooltipIfTextOverflowed :text="`${societyStore.society?.name ?? ''}`" />
            </span>
            <span v-else-if="isAdminPage">
              <CommonsTextTooltipIfTextOverflowed
                :text="`${userStore.user?.firstname ?? ''} ${userStore.user?.lastname ?? ''}`"
              />
            </span>
          </div>
        </div>

        <!-- EXPAND ICON -->
        <uds-icon v-if="!open" icon-name="expand_more" size="small" />
        <uds-icon v-else icon-name="expand_less" size="small" />
      </div>
    </template>

    <template #dropdownContent="{ hide }">
      <!-- USER INFORMATIONS -->
      <div class="container">
        <div class="user-infos">
          <div>
            <div class="uds-headers --h3 user-name truncate">
              <CommonsTextTooltipIfTextOverflowed
                :text="`${userStore[isAdminPage ? 'user' : 'client']?.firstname ?? ''} ${userStore[isAdminPage ? 'user' : 'client']?.lastname ?? ''}`"
              />
            </div>
            <div class="uds-paragraph --small text-neutral">
              {{ userStore[isAdminPage ? "user" : "client"]?.email ?? "" }}
            </div>
          </div>
          <div>
            <uds-tooltip tooltip-text="Déconnexion">
              <uds-action-button
                icon-name="power_settings_new"
                size="small"
                :loading="isLogouting"
                @click="logout"
              >
                Déconnexion
              </uds-action-button>
            </uds-tooltip>
          </div>
        </div>

        <!-- SOCIETIES LIST -->
        <div class="list">
          <div
            v-for="society in selectableSocieties"
            :key="society.id"
            class="societies-list"
            @click="changeSociety(society.id!)"
          >
            <img
              v-if="society.config?.logo"
              :src="society.config?.logo ?? '/avatar.png'"
              style="width: 30px; height: 30px; border-radius: 9999px"
            >
            <uds-icon v-else icon-name="apartment" size="large" :color="udsColors.udsNeutral800" />
            <div style="line-height: 1.3rem">
              <div
                class="uds-paragraph"
                :class="{ 'society-selected': society.id === societyStore.society?.id }"
              >
                {{ society.name }}
              </div>
              <div class="uds-paragraph --small text-neutral">
                SIRET: {{ society.siret ?? "-" }}
              </div>
            </div>
          </div>
        </div>

        <!-- COMPTALIB ADD SOCIETY LINK -->
        <div v-if="isComptalib() && !isAdminPage" class="comptalib-add-society">
          <uds-main-button
            :color="primaryColors.primary500"
            :text-color="udsColors.udsNeutral800"
            @click="
              navigateTo(
                `https://www.comptalib.com/inscription?linked_user=${userStore.client?.id}&linked_token=${token}`,
                { external: true },
              )
            "
          >
            Ajouter un compte
          </uds-main-button>
        </div>

        <!-- GO TO SETTINGS -->
        <div v-if="!isComptalib() && !isAdminPage" class="settings-container">
          <uds-icon icon-name="settings" size="small" :color="udsColors.udsNeutral800" />
          <span
            class="uds-paragraph --small settings-text"
            @click="navigateTo('/settings/informations'), hide()"
          >
            Paramètres
          </span>
        </div>

        <div v-if="isAdminPage" class="shortcuts-container" @click="openShortcuts(), hide()">
          <uds-icon icon-name="help_outline" outlined :color="udsColors.udsNeutral800" />
          Raccourcis clavier
        </div>
      </div>
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import CommonsModalsWaiting from "@/components/commons/modals/Waiting.vue";
import ShortcutsModal from "@/components/commons/modals/ShortcutsModal.vue";

const { isComptalib } = useBrandsComposable();
const { token } = useAuth();
const societyStore = useSocietyStore();
const userStore = useUsersStore();
const route = useRoute();
const authentificationStore = useAuthentificationStore();

const isLogouting = ref(false);
const dropdown = ref<any>(null);
const open = computed<boolean>(() => dropdown.value?.isMenuShown ?? false);

const isAdminPage = computed(() => {
  return route.name?.toString().includes("admin");
});

const primaryColors = computed(() => getPrimaryColors());
const udsColors = getUdsColors();

const selectableSocieties = computed(() =>
  userStore.societies.filter(
    (society) =>
      society.idComptaType !== null &&
      !(society.archive && isDefined(society.step) && society.step !== "done"),
  ),
);

const { open: openModalUser, close: closeModalUser } = useModal({
  component: CommonsModalsWaiting,
});

async function changeSociety(societyId: number) {
  if (societyId !== societyStore.society?.id) {
    dropdown.value.isMenuShown = false;
    await openModalUser();

    try {
      await authentificationStore.connectTo(societyId);
    } catch (error) {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    } finally {
      closeModalUser();
    }
  }
}

async function logout() {
  isLogouting.value = true;
  await authentificationStore.logOut();
  isLogouting.value = false;
}

function openShortcuts() {
  const { open, close } = useModal({
    component: ShortcutsModal,
    attrs: {
      onClose() {
        close();
      },
    },
  });
  open();
}
</script>

<style lang="scss" scoped>
.text-neutral {
  color: $uds-neutral-800;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logo-title {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  max-width: 200px;
}

.society-selected {
  color: $uds-cerulean-500 !important;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-radius: 9999px;
  padding: 0 10px;
  gap: 6px;

  background: $uds-primary-50;
  border: 1px solid $uds-primary-300;
}

.container {
  margin: 10px;
  width: 350px;

  .user-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $uds-spacing-1;
    margin-bottom: 10px;
    gap: $uds-spacing-2;
    border-bottom: 1px solid $uds-neutral-300;

    .user-name {
      padding-bottom: 5px;
      width: 240px;
    }
  }

  .list {
    max-height: 500px;
    overflow-y: scroll;
  }
  .societies-list {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    gap: 20px;
    padding: 5px;

    &:hover {
      cursor: pointer;
      background: $uds-neutral-100;
      border-radius: $uds-radius-1;
    }
  }
}

.comptalib-add-society {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin: $uds-spacing-1_5 0;

  :deep(.uds-main-button) {
    width: 100%;
  }

  .add-society-text {
    color: $uds-neutral-800;
  }
}

.settings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  cursor: pointer;

  .settings-text {
    color: $uds-neutral-800;

    &:hover {
      text-decoration: underline;
    }
  }
}

.shortcuts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $uds-spacing-1;
  color: $uds-neutral-800;
  margin-top: $uds-spacing-1;
  cursor: pointer;
}
</style>

<template>
  <div>
    <CommonsSimpleModal
      :is-open="displayModal"
      :title="modalMetadata.modalTitle"
      :content="modalMetadata.modalText"
      :reject-button-display="statusExtraction === 'finished'"
      :accept-closing="statusExtraction === 'finished'"
      :accept-button-display="true"
      :accept-button-text="modalMetadata.acceptButtonText"
      @close-accept="closeModal()"
      @close-reject="rejectCloseModal()"
    />

    <div v-if="firstWaitingBankStatement" :key="dialogReloadCounter">
      <CommonsModalsAdjustTransactionOfBank
        v-model="showModalAdjust"
        :bank-statement="firstWaitingBankStatement"
        :from-revision="fromRevision"
        @close="removeHandledBankStatementFromWaitlist"
      />
    </div>
    <CommonsSimpleModal
      :is-open="shouldDisplayThatImportIsPending"
      title="Import en cours..."
      content="<div style='text-align: center; font-size: 14px; line-height: 16px;'>Notre outil est en train d’analyser votre relevé bancaire. Cela peut prendre quelques minutes. Vous pouvez continuer la navigation dans votre espace !</div>"
      accept-button-text="Confirmer"
      :accept-closing="false"
      :reject-button-display="false"
      @close-accept="shouldDisplayThatImportIsPending = false"
    />
  </div>
</template>

<script setup lang="ts">
import type { OcrTransactions } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

const props = defineProps<{
  fromRevision: boolean;
}>();

const route = useRoute();
const userStore = useUsersStore();
const adjBankStore = useAdjustBankStatementStore();
const societyStore = useSocietyStore();
const preComptaStore = usePreComptaStore();
const idSociety = computed(() => societyStore?.society?.id ?? 0);
const idUser = computed(() => userStore.user!.id!);

const statusExtraction = computed(() => adjBankStore.statusOcrExtraction);

const displayModal = computed<boolean>(
  () => statusExtraction.value === "pending" || statusExtraction.value === "finished",
);

const modalMetadata = computed(() => {
  switch (statusExtraction.value) {
    case "pending":
      return {
        modalTitle: "Import en cours...",
        modalText:
          "Notre outil est en train d’analyser votre relevé bancaire. Cela peut prendre quelques minutes. Vous pouvez continuer la navigation dans votre espace !",
        acceptButtonText: "Continuer",
      };
    case "finished":
      return {
        modalTitle: "Import du relevé bancaire terminé !",
        modalText: "Vos opérations apparaissent désormais dans votre compte bancaire.",
        acceptButtonText: "Voir mes opérations",
      };
    case "errored":
      return {
        modalTitle: "Echec de l'import.",
        modalText: "Une erreur est survenue lors de l'intégration de vos données.",
        acceptButtonText: "Quitter",
      };
    default:
      return {
        modalTitle: "",
        modalText: "",
        acceptButtonText: "",
      };
  }
});

const bankAccountSelectedForExtraction = computed(
  () => adjBankStore.bankAccountSelectedForExtraction,
);

function rejectCloseModal() {
  adjBankStore.statusOcrExtraction = "inactive";
}

function closeModal() {
  if (statusExtraction.value === "finished") {
    const idBankAccount = bankAccountSelectedForExtraction.value;
    if (route.name === "banks") {
      if (isDefined(idBankAccount)) {
        let url = `/banks?idBankAccount=[${idBankAccount}]`;
        if (props.fromRevision) {
          url = useBuildRouteFromQueryPropertiesComposable(
            defaultPreComptaQueryProperties.value,
            // @ts-expect-error
            {
              ...preComptaStore.queryProperties,
            },
            `/admin/societies/${societyStore.society?.id}/config/precompta`,
          );
        }
        adjBankStore.statusOcrExtraction = "inactive";
        return navigateTo(`${url}#forceRefresh`);
      } else {
        adjBankStore.statusOcrExtraction = "inactive";
        return navigateTo(`/banks#forceRefresh`);
      }
    }
  }
}

// const isSubscribedToSockets = ref(false);
watch(
  () => bankAccountSelectedForExtraction.value,
  (/* newBankAccountId: number | null */) => {
    // FIXME: Sans doute obsolète
    // don't subscribe to sockets from mounted since it is useless if the user did not send anything + it spams requests
    // if (isDefined(newBankAccountId) && !isSubscribedToSockets.value) {
    //   isSubscribedToSockets.value = true;
    //   window.$socket.on(`extraction-error-${idSociety}`, () => {
    //     adjBankStore.statusOcrExtraction = "errored";
    //   });
    //   window.$socket.on(`extraction-ocr-finished-${idSociety}`, () => {
    //     adjBankStore.statusOcrExtraction = "finished";
    //   });
    // }
  },
);

onMounted(() => {
  const roomName = props.fromRevision
    ? `ocr-invoice-process-from-revision-finished-${idUser.value}-${idSociety.value}`
    : `ocr-invoice-process-finished-${idUser.value}-${idSociety.value}`;
  window.$socket.on(roomName, (interpretedBankStatement: OcrTransactions) => {
    adjBankStore.shouldDisplayThatImportIsPending = false;
    const clonedList = cloneDeep(toRaw(adjBankStore.bankStatementsWaitlist));
    const newList = [...clonedList, interpretedBankStatement];
    adjBankStore.bankStatementsWaitlist = newList;
  });
});

const hasBankStatementsWaiting = computed<boolean>(
  () => adjBankStore.bankStatementsWaitlist.length > 0,
);

function removeHandledBankStatementFromWaitlist() {
  // in case the socket came too fast
  // (shouldDisplayThatImportIsPending would be set to false in this file, before it is set to true in AdjustTransactionsOfBankStatement.vue)
  adjBankStore.shouldDisplayThatImportIsPending = false;

  // remove the first element of the waitlist
  const clonedList = cloneDeep(toRaw(adjBankStore.bankStatementsWaitlist));
  const indexToRemove = 0;
  const newList = [...clonedList.slice(0, indexToRemove), ...clonedList.slice(indexToRemove + 1)];
  adjBankStore.bankStatementsWaitlist = newList;

  dialogReloadCounter.value += 1;

  const idBankAccount = bankAccountSelectedForExtraction.value;
  if (isDefined(idBankAccount)) {
    let url = `/banks?idBankAccount=[${idBankAccount}]`;
    if (props.fromRevision) {
      url = useBuildRouteFromQueryPropertiesComposable(
        defaultPreComptaQueryProperties.value,
        // @ts-expect-error
        {
          ...preComptaStore.queryProperties,
        },
        `/admin/societies/${societyStore.society?.id}/config/precompta`,
      );
    }
    return navigateTo(`${url}#forceRefresh`);
  } else {
    return navigateTo(`/banks#forceRefresh`);
  }
}

// each time the first (active) bank statement of the waiting list is changed,
// we want to reload AdjustTransactionsOfBankStatement to update its local state
const dialogReloadCounter = ref(0);

const firstWaitingBankStatement = computed<OcrTransactions | null>(
  () => adjBankStore.bankStatementsWaitlist[0] ?? null,
);

const showModalAdjust = computed(() => {
  return hasBankStatementsWaiting.value && !!firstWaitingBankStatement.value;
});

const shouldDisplayThatImportIsPending = computed<boolean>({
  get() {
    if (hasBankStatementsWaiting.value) {
      return false;
    }

    return adjBankStore.shouldDisplayThatImportIsPending;
  },
  set(newValue) {
    adjBankStore.shouldDisplayThatImportIsPending = newValue;
  },
});
</script>

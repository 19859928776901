import type { Role} from "@silexpert/core";
import { RoleAllowedToLogInAsClient } from "@silexpert/core";

export type ShortCuts = {
  id: number;
  tab: string;
  list: {
    label: string;
    keyboards: { type: "svg" | "letter"; name: string; keyboardSpacer?: string }[];
  }[];
  showCondition?: boolean;
  roles?: Role[];
};

export const ShortcutsList: ShortCuts[] = [
  {
    id: 1,
    tab: "Bilan",
    list: [
      {
        label: "Changer de ligne",
        keyboards: [
          {
            type: "svg",
            name: "arrowUp",
            keyboardSpacer: "ou",
          },
          {
            type: "svg",
            name: "arrowDown",
          },
        ],
      },
      {
        label: "Sélectionner la ligne suivante",
        keyboards: [
          {
            type: "svg",
            name: "shift",
          },
          {
            type: "svg",
            name: "arrowDown",
          },
        ],
      },
      {
        label: "Sélectionner la ligne précédente",
        keyboards: [
          {
            type: "svg",
            name: "shift",
          },
          {
            type: "svg",
            name: "arrowUp",
          },
        ],
      },
      {
        label: "Sélectionner toutes les lignes",
        keyboards: [
          {
            type: "svg",
            name: "alt",
          },
          {
            type: "letter",
            name: "A",
          },
        ],
      },
      {
        label: "Lettrer / Délettrer",
        keyboards: [
          {
            type: "svg",
            name: "alt",
          },
          {
            type: "letter",
            name: "L",
          },
        ],
      },
      {
        label: "Réviser / Annuler la révision",
        keyboards: [
          {
            type: "svg",
            name: "alt",
          },
          {
            type: "letter",
            name: "R",
          },
        ],
      },
      {
        label: "Transférer",
        keyboards: [
          {
            type: "svg",
            name: "alt",
          },
          {
            type: "letter",
            name: "T",
          },
        ],
      },
      {
        label: "Passer au compte, tiers ou transaction suivant(e)",
        keyboards: [
          {
            type: "svg",
            name: "alt",
          },
          {
            type: "svg",
            name: "arrowRight",
          },
        ],
      },
      {
        label: "Revenir au compte, tiers ou transaction précédent(e)",
        keyboards: [
          {
            type: "svg",
            name: "alt",
          },
          {
            type: "svg",
            name: "arrowLeft",
          },
        ],
      },
      {
        label: "Revenir en arrière",
        keyboards: [
          {
            type: "svg",
            name: "escap",
          },
        ],
      },
    ],
    showCondition: true,
    roles: RoleAllowedToLogInAsClient,
  },
];

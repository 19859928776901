<template>
  <div class="leftpanel-container">
    <div class="center-children">
      <template v-if="isCsvFile">
        <SvgCsvSvg style="height: 80vh" />
      </template>
      <template v-else>
        <div id="pdfPaginationArrows" />
        <div class="pdf-wrapper">
          <CommonsPdfViewer
            v-show="!isFileErrored && isFileLoaded"
            :pdf-url="fileUrl"
            :zoom="zoom"
            class="elevation-3-overwrite"
            :class="{ 'zoom-active': zoom > 1 }"
            no-download
            @loaded="(isFileErrored = false), (isFileLoaded = true)"
            @error="(isFileErrored = true), (isFileLoaded = true)"
          />

          <CommonsPdfViewerZoom :zoom="zoom" @zoom-in="zoom = $event" @zoom-out="zoom = $event" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  accountingFile: { id: number; token: string } | null;
  isCsvFile: boolean;
}>();

const societyStore = useSocietyStore();

const fileUrl = ref("");
const isFileLoaded = ref(false);
const isFileErrored = ref(false);
const zoom = ref(1);

const idSociety = computed(() => societyStore?.society?.id ?? 0);

onMounted(async () => {
  if (props.accountingFile) {
    const { id } = props.accountingFile;
    try {
      const buffer = await $silex().file.getBuffer(idSociety.value, id!);
      const blobFile = new File([buffer], "filename", {
        type: "application/pdf",
      });
      const href = window.URL.createObjectURL(blobFile);
      fileUrl.value = href;
    } catch {
      isFileErrored.value = true;
      isFileLoaded.value = true;
    }
  }
});
</script>

<style lang="scss" scoped>
@use "@/assets/styles/halfPage.scss";
</style>

<template>
  <VueFinalModal style="display: flex; justify-content: center; align-items: center">
    <div class="adjust-bank-transaction-container">
      <CommonsModalsAdjustTransactionOfBankLeftPanel
        style="flex-grow: 1"
        :accounting-file="originalBankStatement.file ?? null"
        :is-csv-file="isCsvFile"
      />
      <CommonsModalsAdjustTransactionOfBankRightPanel
        style="width: 720px"
        :transactions="localTransactions"
        :is-csv-file="isCsvFile"
        :is-loading="isLoading"
        @close="$emit('close')"
        @update-transaction="updateTransaction"
        @delete-transaction="deleteTransaction"
        @add-transaction="addTransaction"
        @finish-import="finishImport"
      />
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import set from "lodash-es/set";
import cloneDeep from "lodash-es/cloneDeep";
import type { OcrTransactions, OcrTransactionDetails } from "@silexpert/core";

const emit = defineEmits(["cancel", "close"]);

const props = defineProps<{
  bankStatement: OcrTransactions;
  fromRevision?: boolean;
}>();

const dayjs = useDayjs();
const societyStore = useSocietyStore();
const bankStore = useBankStore();
const preComptaStore = usePreComptaStore();

const isLoading = ref(false);

const originalBankStatement = ref(cloneDeep(toRaw(props.bankStatement)));
const localTransactions = ref(cloneDeep(toRaw(props.bankStatement.transactions)));

const idSociety = computed(() => societyStore?.society?.id ?? 0);
const isCsvFile = computed(() => !props.bankStatement.isPdf);

function updateTransaction({ index, value }: { index: number; value: OcrTransactionDetails }) {
  set(localTransactions.value, index, value);
}

function deleteTransaction(index: number) {
  localTransactions.value.splice(index, 1);
}

function addTransaction() {
  localTransactions.value.push({
    dateValue: dayjs().format("YYYY-MM-DD"),
    label: null,
    credit: 0,
    debit: 0,
  });
}

async function finishImport() {
  isLoading.value = true;
  if (props.fromRevision) {
    await $silex()
      .bankStatement.createTransactionsFromRevision(idSociety.value, {
        transactions: localTransactions.value,
        idBankAccount: originalBankStatement.value.idBankAccount,
      })
      .then(() => {
        emit("close");
        preComptaStore.fetchPreComptaItems();
        preComptaStore.fetchIsDiffEqual0(originalBankStatement.value.idBankAccount);
      })
      .catch((error: any) => {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      });
  } else {
    await $silex()
      .bankStatement.createTransactions({
        transactions: localTransactions.value,
        idBankAccount: originalBankStatement.value.idBankAccount,
      })
      .then(async () => {
        await bankStore.fetchBankAccounts(idSociety.value);
        emit("close");
      })
      .catch((error: any) => {
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      });
  }
  isLoading.value = false;
}
</script>

<style lang="scss" scoped>
.adjust-bank-transaction-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}
</style>

<template>
  <div class="atbr-line-container">
    <uds-input-calendar
      :date="transaction.dateValue"
      :class="{ 'error-style': err.dateValue }"
      style="max-width: 125px"
      @change-date="updateTransactionProperty('dateValue', $event || null)"
    />
    <uds-input-string
      :value="transaction.label"
      style="max-width: 200px"
      :class="{ 'error-style': err.label }"
      @change="updateTransactionProperty('label', $event || null)"
    />
    <uds-input-amount
      :value="transaction.debit"
      :decimals="2"
      :class="{ 'error-style': err.amount }"
      style="max-width: 110px"
      @change="updateTransactionProperty('debit', Math.abs($event))"
    />
    <uds-input-amount
      :value="transaction.credit"
      :decimals="2"
      :class="{ 'error-style': err.amount }"
      style="max-width: 110px"
      @change="updateTransactionProperty('credit', Math.abs($event))"
    />
    <div style="cursor: pointer" @click="$emit('deleteTransaction')">
      <uds-icon icon-name="delete" :color="udsColors.udsNeutral800" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OcrTransactionDetails } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";

const emit = defineEmits<{
  (e: "deleteTransaction"): void;
  (e: "updateTransaction", transaction: OcrTransactionDetails): void;
}>();

const props = defineProps<{
  transaction: OcrTransactionDetails;
}>();

const udsColors = getUdsColors();

function updateTransactionProperty<Property extends keyof OcrTransactionDetails>(
  property: Property,
  value: OcrTransactionDetails[Property],
) {
  const newValues = cloneDeep(toRaw(props.transaction));

  newValues[property] = value;
  if (property === "credit" && value !== 0) {
    newValues.debit = 0;
  }
  if (property === "debit" && value !== 0) {
    newValues.credit = 0;
  }

  emit("updateTransaction", newValues);
}
const err = computed<{ [key: string]: boolean }>(() => {
  const { dateValue, label, credit, debit } = props.transaction;
  return {
    dateValue:
      dateValue === null ||
      dateValue === "" ||
      dateValue === "Invalid date" ||
      dateValue === "0000-00-00",
    label: label === null || label === "",
    amount: credit === 0 && debit === 0,
  };
});
</script>

<style lang="scss" scoped>
.atbr-line-container {
  margin-top: 6px;
  margin-bottom: $uds-spacing-1;

  width: 100%;

  display: flex;
  align-items: center;

  > * {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
</style>
<style>
.error-style .uds-input-balise {
  border: #f05252 solid 1px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<template>
  <div class="atb-right-container">
    <div class="atbr-header">
      <div class="uds-headers">Import de votre relevé bancaire</div>
      <div class="close-button" @click="$emit('close')">
        <uds-icon icon-name="close" :color="udsColors.udsNeutral800" size="large" />
      </div>

      <div class="subtitle">
        Les données ci-dessous ont été remplies grâce à notre
        {{ isCsvFile ? "modèle CSV " : "reconnaissance optique" }}. <br >
        Merci de vérifier les données, et de les modifier si besoin.
      </div>
    </div>

    <div class="atbr-infos">
      Vos opérations du <span class="primary-color">{{ startDate }}</span> au
      <span class="primary-color">{{ endDate }}</span>
    </div>

    <div class="atbr-content">
      <div class="atbr-content-header">
        <div style="width: 135px">Date</div>
        <div style="width: 200px">Libellé</div>
        <div style="width: 100px">Débit</div>
        <div style="width: 100px">Crédit</div>
      </div>
      <div class="atbr-content-list scrollbar">
        <template v-for="(transaction, index) in transactions" :key="index">
          <CommonsModalsAdjustTransactionOfBankTableLine
            :transaction="transaction"
            @update-transaction="$emit('updateTransaction', { index, value: $event })"
            @delete-transaction="$emit('deleteTransaction', index)"
          />
        </template>
      </div>
      <div class="atbr-content-footer" @click="$emit('addTransaction')">
        <uds-icon icon-name="add_circle" :color="udsColors.udsGoogleBlue" size="medium" />

        Ajouter une opération bancaire
      </div>
    </div>

    <div class="atbr-resume">
      Total des mouvements sur la période :
      <uds-tag color="neutral"><uds-amount-currencie :value="totalDebit" /></uds-tag>

      <uds-tag><uds-amount-currencie class="--success" :value="totalCredit" /></uds-tag>
    </div>

    <div class="atbr-footer">
      <uds-main-button
        size="small"
        :disabled="!areTransactionsValid || isLoading"
        :loading="isLoading"
        @click="areTransactionsValid ? $emit('finishImport') : null"
      >
        Terminer l’import
      </uds-main-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OcrTransactionDetails } from "@silexpert/core";

defineEmits(["finishImport", "updateTransaction", "addTransaction", "deleteTransaction", "close"]);

const props = defineProps<{
  transactions: OcrTransactionDetails[];
  isCsvFile: boolean;
  isLoading: boolean;
}>();

const dayjs = useDayjs();

const udsColors = getUdsColors();

const startDate = computed<string | null>(() => {
  const transactionsWithDate = props.transactions.filter((t) => isDefined(t.dateValue));
  if (transactionsWithDate.length === 0) {
    return null;
  }
  const transactionsMoments = transactionsWithDate.map((t) => dayjs(t.dateValue));
  const minMoment = dayjs.min(transactionsMoments);
  return minMoment!.format("DD/MM/YYYY");
});

const endDate = computed<string | null>(() => {
  const transactionsWithDate = props.transactions.filter((t) => isDefined(t.dateValue));
  if (transactionsWithDate.length === 0) {
    return null;
  }
  const transactionsMoments = transactionsWithDate.map((t) => dayjs(t.dateValue));
  const maxMoment = dayjs.max(transactionsMoments);
  return maxMoment!.format("DD/MM/YYYY");
});

const totalDebit = computed(() => {
  return props.transactions.reduce((acc, tr) => acc + tr.debit, 0);
});

const totalCredit = computed(() => {
  return props.transactions.reduce((acc, tr) => acc + tr.credit, 0);
});

const areTransactionsValid = computed<boolean>(() => {
  return props.transactions.every((t) => {
    const { dateValue, label, credit, debit } = t;
    return (
      dateValue !== null &&
      dateValue !== "" &&
      dateValue !== "Invalid date" &&
      dateValue !== "0000-00-00" &&
      label !== null &&
      label !== "" &&
      (credit !== 0 || debit !== 0)
    );
  });
});
</script>

<style lang="scss" scoped>
.atb-right-container {
  background-color: $uds-white;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.atbr-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $uds-spacing-2;
  padding: 0 $uds-spacing-4;
  margin: $uds-spacing-3 0;

  .subtitle {
    color: $uds-neutral-800;
    font-size: 14px;
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 35px;
    right: 35px;

    width: 14px;
    height: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}

.atbr-infos {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: $uds-spacing-2;
  padding: 0 $uds-spacing-4;
}

.--success {
  color: $uds-success-500 !important;
}

.atbr-resume {
  display: flex;
  align-items: center;
  gap: $uds-spacing-1;
  margin: $uds-spacing-3 0;
  padding: 0 $uds-spacing-4;
}

.atbr-content {
  height: calc(100vh - 330px);
  background: $uds-neutral-50;
  border-radius: $uds-radius-1;
  margin: 0 $uds-spacing-4;

  .atbr-content-header {
    padding-left: 26px;
    height: 52px;
    border-bottom: 1px solid $uds-neutral-600;

    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 18px;
    color: $uds-neutral-900;

    > * {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  .atbr-content-list {
    padding-left: $uds-spacing-3;
    height: calc(100vh - 440px);
  }

  .atbr-content-footer {
    padding-left: $uds-spacing-3;
    height: 52px;
    border-top: 1px solid $uds-neutral-600;
    display: flex;
    align-items: center;
    gap: $uds-spacing-0_5;
    cursor: pointer;
    color: $uds-google-blue;
  }
}

.atbr-footer {
  box-shadow: 0px -5px 4px rgba(0, 0, 0, 0.03);
  padding: $uds-spacing-3;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  gap: $uds-spacing-3;
}
</style>

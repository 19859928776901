<template>
  <VueFinalModal style="display: flex; justify-content: center; align-items: center">
    <div class="sc-container">
      <div class="sc-title uds-headers">
        Raccourcis clavier
        <div class="sc-title-closeCross" @click="$emit('close')">
          <uds-icon :color="udsColors.udsNeutral800" icon-name="close" size="large" />
        </div>
      </div>
      <div class="sc-searchbar">
        <CommonsDropdownsDropdownSearch
          v-model:data="search"
          placeholder="Rechercher une action..."
        />
      </div>
      <div class="sc-navbar">
        <div
          v-for="(shortcut, indexSc) in filteredList"
          :key="`shortcut_${indexSc}`"
          class="uds-headers --h2"
          @click="currentTab = shortcut.id"
        >
          {{ shortcut.tab }}
        </div>
      </div>
      <div class="sc-content scrollbar">
        <div
          v-for="(shortcut, indexScF) in filteredShortcuts"
          :key="`shortcutFil_${indexScF}`"
          class="sc-content-line"
        >
          <CommonsTextTooltipIfTextOverflowed :text="shortcut.label" />
          <div class="sc-cl-keyboards">
            <template
              v-for="(keyboard, indexKey) in shortcut.keyboards"
              :key="`keyboard_${indexKey}`"
            >
              <template v-if="keyboard.type === 'svg'">
                <img :src="images[keyboard.name]" >
              </template>
              <div v-else class="keyboard-container">
                {{ keyboard.name }}
              </div>
              <div v-if="keyboard.keyboardSpacer">
                {{ keyboard.keyboardSpacer }}
              </div>
            </template>
          </div>
        </div>
        <CommonsNoData v-if="noData" :text="'Aucun raccourci ne correspond à votre recherche.'" />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import { filename } from "pathe/utils";

const udsColors = getUdsColors();
const roleComposable = useRolesComposable();

defineEmits(["close"]);

const currentTab = ref<number>(1);
const search = ref<string | null>(null);

// @ts-expect-error
const glob = import.meta.glob("@/assets/svg/keyboard/*.svg", { eager: true });
const images = Object.fromEntries(
  // @ts-expect-error
  Object.entries(glob).map(([key, value]) => [filename(key), value.default]),
);

const filteredList = computed<ShortCuts[]>(() =>
  ShortcutsList.filter((list) => {
    if (list.roles) return roleComposable.hasOneOfTheseRoles(list.roles);
    return true;
  }),
);

const selectedList = computed(
  () => filteredList.value.find((sc) => sc.id === currentTab.value) ?? { list: [] },
);

const filteredShortcuts = computed(() =>
  isDefined(search.value)
    ? selectedList.value.list.filter((shortcut) =>
        removeAccents(shortcut.label.toLowerCase()).includes(
          removeAccents(search.value!.toLowerCase()),
        ),
      )
    : selectedList.value.list,
);

const noData = computed(() => filteredShortcuts.value.length === 0);
</script>

<style lang="scss" scoped>
.keyboard-container {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px $uds-neutral-400 solid;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.sc-container {
  width: min(90vw, 665px);
  height: 80vh;
  background: $uds-white;
  border-radius: $uds-radius-2;

  padding: $uds-spacing-2 $uds-spacing-3;
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-3;

  .sc-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .sc-title-closeCross {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  .sc-searchbar {
    height: 40px;
    min-height: 40px;

    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $uds-neutral-400;
  }

  .sc-navbar {
    display: flex;
    gap: $uds-spacing-2;
    cursor: pointer;
    padding-bottom: $uds-spacing-1;
    border-bottom: 1px solid $uds-neutral-400;
  }

  .sc-content {
    display: flex;
    flex-direction: column;
    gap: $uds-spacing-3;

    max-height: calc(80vh - 174px);

    .sc-content-line {
      display: flex;
      justify-content: space-between;

      .sc-cl-keyboards {
        display: flex;
        gap: $uds-spacing-1;
        align-items: center;
      }
    }
  }
}
</style>
